.invalid-field {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.border-input-default {
  border-color: #f0f2f4 !important;
}

.login--logo-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 30px;
}

.login--logo-container img {
  height: 100%;
  width: auto;
}

.header-dashboard--logo-container {
  height: 30px;
}

.header-dashboard--logo-container img {
  height: 100%;
  width: auto;
}

.label-status-notification {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  color: #ffffff;
  background-color: #dc3545;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  margin-left: 5px;
  font-weight: 500;
  line-height: 0;
}

.nav-link.new-project {
  min-width: 134px;
  display: flex;
  align-items: center;
}

.bg-dark-grey {
  background-color: #5a5b5d;
}

.agreement-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px 0 0;
}

.agreement-img {
  width: 100%;
  height: auto;
  transition: transform 0.5s ease-in-out;
}

.agreement-nav-group {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.agreement-nav-item {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background-color: #5a5b5d;
  color: #ffffff;
  border: none;
  margin: 0 3px;
}
