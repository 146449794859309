.containerMessage {
    display: flex;
    height: 90vh;
    margin-bottom: 5%;
}

.contactList {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 16px;
    min-width: 336px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 16px 0px 0px 16px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    overflow-y: auto;
}

.chatContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    border: 1px solid #D9D9D9;
    border-radius: 0px 8px 8px 0px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: #f6f9fc;
}

.chatHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 16px 16px;
    gap: 16px;
    width: 100%;
    background: #f9ffcd;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 0px 16px 0px 0px;
}

.chatList {
    display: flex;
    flex-direction: column;
    padding: 20px;
    overflow-y: auto;
    width: 100%;
    height: 80%;
    gap: 15px;
}

.chatBoxLeft {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 15px;
    gap: 16px;
    width: fit-content;
    min-width: 250px;
    max-width: 590px;
    height: fit-content;
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);
    border-radius: 16px 16px 16px 0px;
}

.chatBoxRight {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 15px;
    gap: 16px;
    width: auto;
    min-width: 250px;
    max-width: 590px;
    height: fit-content;
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);
    border-radius: 16px 16px 0px 16px;
    margin-left: auto;
}

.chatLabelDate {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 16px;
    max-width: 150px;
    background: #FFFFFF;
    border: 1px solid #DEE3ED;
    border-radius: 999px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
}

.chatInsideLeft {
    width: 90%;
}

.chatInsideRight {
    display: flex;
    flex-direction: column;
    width: 15%;
    height: 100%;
    justify-content: space-between;
    text-align: right !important;
}

.chatInsideBottom {
    margin-top: 15px;
    text-align: end;
}

.chatInsideTop {
    margin-bottom: 15px;
}

.imgStatusSended {
    width: 18px;
    height: 11px;
}

.imgStatusReaded {
    width: 26px;
    height: 17px;
}

.chatInputContainer {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    padding: 15px;
    gap: 16px;
    background: #FFFFFF;
    border-radius: 0px 0px 16px 0px;
    width: 100%;
    max-height: 350px;
}

.chatInputElement {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 14px;
    gap: 8px;
    background: #FFFFFF;
    border: 1px solid #DEE3ED;
    border-radius: 8px;
    width: 100%;
    height: 90%;
}

.btnInsideChat {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px;
    gap: 10px;
    background: #F6F9FC;
    border-radius: 999px;
    width: 40px;
    height: 40px;
    border: none;
}

.btnInsideChat:hover,
.contactBox:hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.inputMessage {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    width: 100%;
    border: none;
}

.inputMessage:focus {
    outline: none;
}

.contactBox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 15px;
    gap: 8px;
    background: #EDF0F2;
    border-radius: 8px;
    width: 100%;
    border: none;
    text-align: left;
}

.boxInsideLeft {
    width: 80%;
}

.boxInsideRight {
    display: flex;
    flex-direction: column;
    width: 10%;
    height: 100%;
    justify-content: space-between;
}

.boxInsideTop {
    margin-bottom: 15px;
}

.center-message-list {
    justify-content: center !important;
    align-items: center !important;
}

.selectedDialog {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background: #bcc6cd;
}

.messageAttachmentContainer {
    display: flex;
    flex-flow: wrap;
    gap: 10px;
}

.imgAttachment {
    height: 100px;
    width: 100px;
    object-fit: cover;
}

.previewImageContainer {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.previewImageItemContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}