.scrollableDiv {
  width: 100%;
  height: 70vh;
  overflow: hidden;

  position: relative;
}

.content {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  position: relative;
  padding: 0 10px 0 0;
}

/* width */
.content::-webkit-scrollbar {
  width: 10px;
  z-index: 100;
}

/* Track */
.content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.content::-webkit-scrollbar-thumb {
  background: #555;
}

/* Handle on hover */
.content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.inner-content {
  width: 100%;
  height: 500px; /* Make this height larger than the parent to enable scrolling */
}

/* .scrollTracker {
  width: 100%;
  height: 5px;
  background-color: #ddd;
  position: absolute;
  bottom: 0;
}

.scrollIndicator {
  height: 100%;
  background-color: #007bff;
  width: 0;
} */
